<template>
  <div class="detailBookingPage">
    <Guard permission="bookings.edit" v-slot="{ isAvailable }">
      <back-title @click="goBack">
        {{ $t("Booking details") }}
        <span v-if="booking" class="detailBookingPage__reg-code">{{
          booking.registrationCode
        }}</span>
        <template v-if="isAvailable && !isAbandoned" v-slot:append>
          <Icon name="edit" is-clickable @click="handleEditClick" />
        </template>
      </back-title>
    </Guard>
    <Loader v-if="isLoading" color="primary" size="m" class="refund-loader" />
    <div v-else-if="booking" class="detailBookingPage__content">
      <div class="detailBookingPage__row m-flex">
        <p class="detailBookingPage__text m-bold">
          {{ booking.reservationInfo.firstName }}
          {{ booking.reservationInfo.lastName }}
        </p>
        <Icon name="info" is-clickable @click="goToPersonalInfo" />
      </div>
      <div class="detailBookingPage__row m-flex">
        <p
          :class="[
            'detailBookingPage__text m-bold',
            { 'm-primary': isUpcoming && !isCanceled },
          ]"
        >
          {{ formattedTime }}
        </p>
        <p v-if="isCanceled" class="detailBookingPage__text m-canceled">
          {{ $t("Canceled") }}
        </p>
        <p v-else-if="isAbandoned" class="detailBookingPage__text m-ended">
          {{ $t("Abandoned") }}
        </p>
        <p v-else-if="!isUpcoming" class="detailBookingPage__text m-ended">
          {{ $t("Ended") }}
        </p>
        <p v-else class="detailBookingPage__text m-ended">
          {{ this.booking.status }}
        </p>
      </div>
      <div class="detailBookingPage__row">
        <span class="detailBookingPage__text"> {{ $t("Walk-in") }}: </span>
        <span class="detailBookingPage__text m-bold">
          {{ booking.isWalkIn ? "Yes" : "No" }}
        </span>
      </div>
      <div class="detailBookingPage__row">
        <span class="detailBookingPage__text">
          {{ $t("Registration code") }}:
        </span>
        <span class="detailBookingPage__text m-bold">
          {{ booking.registrationCode }}
        </span>
      </div>
      <div class="detailBookingPage__row">
        <span class="detailBookingPage__text">
          {{ $t("Number of participants") }}:
        </span>
        <span class="detailBookingPage__text m-bold">
          {{
            !booking?.isSpecifyGroupSize && booking.selectedGroup
              ? `${booking.selectedGroup.minimum}-${booking.selectedGroup.maximum}`
              : booking.playersCount
          }}
        </span>
      </div>
      <div class="detailBookingPage__row">
        <span class="detailBookingPage__text"> {{ $t("Cutoff time") }}: </span>
        <span class="detailBookingPage__text m-bold">
          {{ product.cutoffTime }} {{ getHoursText(product.cutoffTime) }}
        </span>
      </div>
      <div class="detailBookingPage__row m-flex" v-if="booking.activeWaiver">
        <p>
          <span class="detailBookingPage__text">
            {{ $t("Signed waivers") }}:
          </span>
          <span class="detailBookingPage__text m-bold">
            {{ signatures.length }}
          </span>
        </p>
        <router-link
          :to="{ name: 'BookingSignatures' }"
          class="detailBookingPage__text m-link"
        >
          {{ $t("View") }}
        </router-link>
      </div>
      <div v-if="upsellItemsString" class="detailBookingPage__row">
        <span class="detailBookingPage__text">
          {{ $t("Upsell merchandise") }}:
        </span>
        <span class="detailBookingPage__text m-bold">
          {{ upsellItemsString }}
        </span>
      </div>
      <div class="detailBookingPage__price">
        <BookingPriceBreakdown
          :price-data="price"
          :players-count="+booking.playersCount"
          :product-name="product && product.name"
        />
      </div>
      <BookingExpiredCountdown
        v-if="booking && booking.expiresAt && !isPaymentExpired"
        :expires-at="booking.expiresAt"
        :isPaymentExpired.sync="isPaymentExpired"
        @expired="handleExpired"
      />
      <div class="detailBookingPage__actions">
        <Guard permission="bookings.collect" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable && isCheckoutNotPaid && !isPaymentExpired"
            :is-loading="isCheckout"
            variant="primary"
            is-small
            :is-block="['xs', 'sm'].includes($mq)"
            :is-disabled="isCancelling"
            @click="handleBookingPay"
            >{{ $t("Select payment method") }}
          </Button>
        </Guard>
        <Guard permission="bookings.cancel" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable && !isCanceled && isUpcoming && !isAbandoned"
            variant="danger"
            is-small
            is-outlined
            :is-block="['xs', 'sm'].includes($mq)"
            :is-loading="isCancelling"
            :is-disabled="isCheckout"
            @click="handleBookingCancel"
          >
            {{ $t("Cancel booking") }}
          </Button>
        </Guard>
        <Guard permission="signatures.create" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable && booking.activeWaiver"
            variant="secondary"
            is-small
            is-outlined
            :is-block="['xs', 'sm'].includes($mq)"
            @click="goToSigningWaiver"
          >
            {{ $t("Sign waiver") }}
          </Button>
        </Guard>
        <Guard permission="bookings.refund" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable && availableForRefund && availableForRefund > 0"
            variant="secondary"
            is-small
            is-outlined
            :is-block="['xs', 'sm'].includes($mq)"
            @click="goToRefund"
          >
            {{ $t("Issue refund") }}
          </Button>
        </Guard>
        <Guard permission="bookings.sendEmail" v-slot="{ isAvailable }">
          <Button
            v-if="isAvailable"
            variant="secondary"
            is-small
            is-outlined
            :is-block="['xs', 'sm'].includes($mq)"
            @click="$modal.show('booking-mail-modal')"
          >
            {{ $t("Send email") }}
          </Button>
        </Guard>
      </div>
    </div>
    <booking-logs v-if="!isLoading" :logs="logs" @added="loadLogs" />
    <RefundBookingDialog />
    <CancelBookingConfirm />
    <BookingMailModal
      v-if="booking && booking.reservationInfo"
      :email="booking.reservationInfo.email"
      :is-email-sending="isEmailSending"
      @send="handleSendConfirmation"
    />
    <WithoutPaymentOptionDialog
      v-if="booking"
      has-stripe
      :selected-group="booking.selectedGroup"
      :checkout-info="price"
      :saved-cards="savedPaymentMethods"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getFormattedBookingTime,
  getFormattedSlots,
  getBookingPrice,
  sleep,
} from "@/helpers/utils";
import moment from "moment";
import { BookingStatusEnum, BookingPricingTypeEnum } from "@/helpers/enums";
import dialog from "@/plugins/dialog";
import BookingService from "@/api/services/BookingService";
import RefundBookingDialog, {
  refundDialog,
} from "@/components/bookings/refund/RefundBookingDialog";
import BookingMailModal from "@/components/bookings/BookingMailModal";
import CancelBookingConfirm, {
  cancelConfirmDialog,
} from "@/components/bookings/CancelBookingConfirm";
import BookingLogs from "@/components/bookings/logs/BookingLogs";
import Guard from "@/components/common/Guard";
import WithoutPaymentOptionDialog, {
  withoutPaymentOptionDialog,
} from "@/components/bookings/WithoutPaymentOptionDialog";
import BookingExpiredCountdown from "@/components/bookings/BookingExpiredCountdown";
import BookingPriceBreakdown from "@/components/bookings/BookingPriceBreakdown.vue";

export default {
  name: "DetailBookingPage",
  components: {
    BookingPriceBreakdown,
    BookingExpiredCountdown,
    Guard,
    BookingLogs,
    CancelBookingConfirm,
    BookingMailModal,
    RefundBookingDialog,
    BackTitle,
    WithoutPaymentOptionDialog,
  },
  data: () => ({
    BookingPricingTypeEnum,
    isLoading: false,
    isEmailSending: false,
    isCheckout: false,
    isCancelling: false,
    isCheckoutNotPaid: false,
    isPaymentExpired: false,
    logs: [],
  }),
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      booking: (state) => state.bookings.editedItem,
      products: (state) => state.products.list,
      signatures: (state) => state.bookings.signatures,
      savedPaymentMethods: (state) => state.bookings.editedItemPaymentMethods,
    }),
    product() {
      return (
        this.products.find((item) => item.id === this.booking.productId) || {}
      );
    },
    price() {
      return (
        this.booking?.checkoutInfo && getBookingPrice(this.booking.checkoutInfo)
      );
    },
    formattedTime() {
      return `${getFormattedBookingTime(this.booking)} ${getFormattedSlots(
        this.booking,
        this.venue.timezone,
      )}`;
    },
    isUpcoming() {
      return (
        (this.booking.date.seconds || this.booking.date._seconds) * 1000 >=
        moment.utc().valueOf()
      );
    },
    isCanceled() {
      return (
        !this.booking || this.booking.status === BookingStatusEnum.CANCELED
      );
    },
    isAbandoned() {
      return (
        !this.booking || this.booking.status === BookingStatusEnum.ABANDONED
      );
    },
    paidAmount() {
      if (!this.price?.paid) return false;

      return this.price.paid / 100;
    },
    availableForRefund() {
      if (!this.price?.paid) return false;

      return this.price.availableForRefund / 100;
    },
    refundedAmount() {
      const amount = this.price?.refunded;
      if (!amount) return false;

      return amount / 100;
    },
    upsellItemsString() {
      if (this.price && this.price.upsellItems) {
        return this.price.upsellItems
          .map(
            (item) =>
              `${item.name}${item.quantity > 1 ? ` x${item.quantity}` : ""}`,
          )
          .join(", ");
      }
      return false;
    },
  },
  async created() {
    this.isLoading = true;

    const { redirect_status: paymentStatus } = this.$route.query;
    if (paymentStatus) {
      await this.$router.replace({ query: {} });
      await this.showPaymentResult(paymentStatus === "succeeded");
      await sleep(7000);
    }

    try {
      await this.getBooking(this.$route.params.id);
      await Promise.allSettled(
        [
          this.getSignatures(),
          this.loadLogs(),
          !this.products.length && this.fetchProducts(),
          this.fetchBookingPaymentMethods(),
        ].filter(Boolean),
      );

      const convertedPrice = getBookingPrice(this.booking?.checkoutInfo);
      const paymentDue =
        (convertedPrice.total -
          ((convertedPrice.paid || 0) - (convertedPrice.refunded || 0))) /
        100;

      if (paymentDue > 0) {
        this.isCheckoutNotPaid = true;
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      getBooking: "bookings/fetchBookingById",
      getSignatures: "bookings/fetchSignatures",
      updateBooking: "bookings/updateBooking",
      checkout: "bookings/checkout",
      sendEmail: "bookings/sendEmail",
      getLogs: "bookings/getLogs",
      fetchProducts: "products/fetchProducts",
      fetchBookingPaymentMethods: "bookings/fetchBookingPaymentMethods",
    }),
    ...mapMutations({
      setEditedBooking: "bookings/SET_EDITED_ITEM",
    }),
    handleExpired() {
      this.getBooking(this.$route.params.id);
    },
    async showPaymentResult(isSuccessPaid) {
      const alertOptions = isSuccessPaid
        ? {
            title: "Payment was successful!",
            okText: "Done",
          }
        : {
            title: "Some problem with payment...",
            okText: "Done",
          };
      await dialog.alert(alertOptions);
    },
    async loadLogs() {
      this.logs = await this.getLogs();
    },
    async handleBookingCancel() {
      let refundConfirmed = false;
      let refundAmount = undefined;
      if (this.paidAmount !== this.refundedAmount) {
        const confirmChoice = await cancelConfirmDialog.open({
          datetime: this.formattedTime,
        });
        if (confirmChoice === "back") {
          return;
        }
        refundConfirmed = confirmChoice === "refund";
        if (refundConfirmed) {
          refundAmount = await refundDialog.open({
            checkoutInfo: this.price,
            refundAmountFull: this.price.availableForRefund / 100,
          });
          if (refundAmount === false) {
            return;
          }
        }
      } else {
        const confirmed = await dialog.confirm({
          title: "Cancel booking?",
          message: `You want to cancel booking on<br />${this.formattedTime}`,
          isHtml: true,
          okText: "Cancel booking",
          cancelText: "Back",
        });
        if (!confirmed) {
          return;
        }
      }
      try {
        this.isCancelling = true;
        if (refundConfirmed) {
          await BookingService.refundBooking({
            bookingId: this.booking.id,
            amount: refundAmount ? Math.round(refundAmount * 100) : undefined,
          });
        }
        await this.updateBooking({
          id: this.booking.id,
          data: { status: BookingStatusEnum.CANCELED },
        });
        this.goBack();
      } finally {
        this.isCancelling = false;
      }
    },
    goToRefund() {
      this.$router.push({
        name: "BookingRefund",
      });
    },
    goToSigningWaiver() {
      this.$router.push({
        name: "BookingSignaturesSign",
      });
    },
    goBack() {
      this.$router.push({
        name: "Bookings",
        params: {
          isKeepDate: true,
        },
      });
    },
    goToPersonalInfo() {
      this.$router.push({
        name: "BookingPersonalInfo",
      });
    },
    getHoursText(hours) {
      return this.$t(+hours === 1 ? "hour" : "hours");
    },
    async handleBookingPay() {
      try {
        const customPrice = this.booking?.checkoutInfo.isCustom
          ? this.booking?.checkoutInfo.subtotal / 100
          : undefined;
        const {
          option: withoutPaymentOption,
          amountToCollect,
          personsToDeposit,
          isSkipPayment,
          paymentMethodId,
        } = await withoutPaymentOptionDialog.open();
        if (withoutPaymentOption !== null) {
          this.isCheckout = true;
          await this.checkout({
            productId: this.product.id,
            bookingId: this.booking.id,
            data: {
              isSkipPayment,
              customPrice,
              withoutPaymentOption,
              paymentMethodId,
              amountToCollect,
              personsToDeposit,
            },
          });
          if (isSkipPayment || withoutPaymentOption) {
            this.goBack();
          } else {
            await this.$router.push({
              name: "BookingCheckout",
            });
          }
        }
      } catch {
        await dialog.alert({
          title: "Some problem with payment...",
          okText: "Done",
        });
      } finally {
        this.isCheckout = false;
      }
    },
    async handleSendConfirmation(form) {
      try {
        this.isEmailSending = true;
        await this.sendEmail(form);
        this.$modal.hide("booking-mail-modal");
      } catch (e) {
        console.log(e);
      } finally {
        this.isEmailSending = false;
      }
    },
    handleEditClick() {
      this.$router.push({
        name: "BookingEdit",
      });
    },
  },
};
</script>
<style lang="scss">
.detailBookingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  height: 100%;

  &__content {
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 0 16px 48px;
    width: 100%;

    @media (min-width: $media-laptop) {
      padding: 0 24px 48px;
    }
  }

  &__row {
    padding: 24px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $secondary-300;
    }

    &.m-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    text-transform: capitalize;
    &.m-bold {
      font-weight: 500;
    }

    &.m-link {
      font-weight: 500;
      text-decoration: underline;
      color: $primary;
    }

    &.m-primary {
      color: $primary;
    }

    &.m-canceled {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $danger;
    }

    &.m-ended {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $secondary-400;
    }
  }

  &__reg-code {
    line-height: 1.5;
    color: $secondary-400;
  }

  &__price {
    margin: 24px 0 48px;

    @media (min-width: $media-laptop) {
      margin-bottom: 64px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
