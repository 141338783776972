<template>
  <custom-modal :name="name" @before-close="refundDialog.cancel()">
    <div class="refund-booking-dialog">
      <RefundBookingForm
        v-model="refundAmount"
        :checkout-info="refundDialog.state.checkoutInfo"
        :refund-amount-full="refundDialog.state.refundAmountFull"
      />
      <div class="refund-booking-dialog__actions">
        <Button
          is-block
          :isDisabled="isInvalid"
          @click="refundDialog.ok(refundAmount)"
        >
          Refund ${{
            refundAmount === null
              ? refundDialog.state.refundAmountFull
              : refundAmount || 0
          }}
        </Button>
        <Button
          is-block
          isOutlined
          variant="secondary"
          @click="refundDialog.cancel()"
        >
          Cancel
        </Button>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import RefundBookingForm from "@/components/bookings/refund/RefundBookingForm";
import Vue from "vue";

const state = Vue.observable({
  active: false,
  checkoutInfo: null,
  refundAmountFull: 0,
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));

const reset = () => {
  state.active = false;
  state.checkoutInfo = null;
  state.refundAmountFull = 0;
};

export const refundDialog = {
  get state() {
    return state;
  },

  open({ checkoutInfo, refundAmountFull }) {
    state.checkoutInfo = checkoutInfo;
    state.refundAmountFull = refundAmountFull;
    state.active = true;
    return dialogPromise();
  },

  ok(amount) {
    close(amount);
    reset();
  },

  cancel() {
    close(false);
    reset();
  },
};

export default {
  name: "RefundBookingDialog",
  components: { RefundBookingForm },
  data() {
    return {
      refundAmount: null,
      refundDialog,
      name: "refund-dialog",
    };
  },
  computed: {
    isActive() {
      return this.refundDialog.state.active;
    },
    isInvalid() {
      return !(
        this.refundAmount === null ||
        (this.refundAmount > 0 &&
          this.refundAmount < this.refundDialog.state.refundAmountFull)
      );
    },
  },
  watch: {
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-booking-dialog {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 64px;

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
