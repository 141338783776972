<template>
  <CustomModal
    :name="modalName"
    isScrollable
    classes="bookingLogsCreateModal"
    @before-close="close"
  >
    <template v-slot:header>
      <BackTitle class="mb-40" @click="$modal.hide(modalName)">{{
        $t("Add note")
      }}</BackTitle>
    </template>
    <Form v-slot="{ invalid }">
      <FormItem rules="required">
        <Input
          v-model="comment"
          label="Comment"
          placeholder="Text"
          is-textarea
        />
      </FormItem>
      <div class="bookingLogsCreateModal__actions">
        <Button
          is-block
          :is-loading="isLoading"
          :is-disabled="invalid"
          @click="$emit('add', comment)"
        >
          {{ $t("Add") }}
        </Button>
        <Button
          is-block
          is-outlined
          variant="secondary"
          @click="$modal.hide(modalName)"
        >
          {{ $t("Cancel") }}
        </Button>
      </div>
    </Form>
  </CustomModal>
</template>
<script>
import BackTitle from "@/components/common/BackTitle";

export default {
  name: "BookingLogsCreateModal",
  components: { BackTitle },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modalName: "booking-logs-create-modal",
    comment: "",
  }),
  methods: {
    close() {
      this.comment = "";
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.bookingLogsCreateModal {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}
</style>
