<template>
  <CustomModal
    :name="modalName"
    isScrollable
    classes="bookingMailModal"
    width="70%"
    @before-close="close"
  >
    <template v-slot:header>
      <BackTitle class="mb-40" @click="$modal.hide(modalName)">
        Send email
      </BackTitle>
    </template>
    <Form v-slot="{ invalid }" class="flex-column">
      <FormItem rules="required|email">
        <Input v-model="form.email" label="Email" placeholder="Enter email" />
      </FormItem>
      <FormItem rules="required">
        <Select
          v-model="form.type"
          :options="typeOptions"
          label="Type"
          placeholder="Select a type"
        />
      </FormItem>
      <template v-if="form.type === CUSTOM_MESSAGE_TYPE">
        <FormItem rules="required">
          <Input v-model="form.subject" label="Subject" placeholder="Subject" />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.message"
            label="Message"
            placeholder="Enter message"
            is-textarea
          />
        </FormItem>
      </template>
      <FormItem
        rules="required"
        v-else-if="form.type === BookingMailTypesEnum.CUSTOM"
      >
        <Select
          v-model="form.templateId"
          :options="customTemplates"
          label="Template"
          :disabled="!customTemplates || !customTemplates.length || isLoading"
          placeholder="Select a template"
        />
      </FormItem>
      <div class="bookingMailModal__actions">
        <Button
          variant="secondary"
          is-block
          is-small
          :is-disabled="invalid"
          :is-loading="isPreviewLoading"
          @click="handleShowPreview"
        >
          Preview
        </Button>
        <div class="divider"></div>
        <Button
          is-block
          :is-loading="isEmailSending"
          :isDisabled="invalid || isPreviewLoading"
          @click="handleSubmit"
        >
          {{ $t("Send") }}
        </Button>
        <Button
          is-block
          is-outlined
          variant="secondary"
          @click="$modal.hide(modalName)"
        >
          {{ $t("Cancel") }}
        </Button>
      </div>
    </Form>
    <MailPreviewModal
      :modal-name="previewModalName"
      :previews="previews"
      :booking-id="bookingId"
      :subject="form.subject"
      :template-id="form.templateId"
      :message="form.message"
      :type="form.type"
    />
  </CustomModal>
</template>

<script>
import { BookingMailTypesEnum } from "@/helpers/enums";
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import MailPreviewModal from "@/components/mailing-templates/MailPreviewModal.vue";
import MailingTemplatesService from "@/api/services/MailingTemplatesService";

export default {
  name: "BookingMailModal",
  components: { MailPreviewModal, BackTitle },
  props: {
    email: {
      type: String,
      required: true,
    },
    isEmailSending: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    const CUSTOM_MESSAGE_TYPE = "customMessage";
    return {
      BookingMailTypesEnum,
      modalName: "booking-mail-modal",
      isLoading: false,
      form: {
        email: "",
        type: "",
        templateId: "",
        subject: "",
        message: "",
      },
      typeOptions: [
        {
          value: BookingMailTypesEnum.CONFIRMATION,
          name: "Confirmation",
        },
        {
          value: BookingMailTypesEnum.REMINDER,
          name: "Reminder",
        },
        {
          value: BookingMailTypesEnum.CANCELLATION,
          name: "Cancellation",
        },
        {
          value: BookingMailTypesEnum.CUSTOM,
          name: "Custom Template",
        },
        {
          value: CUSTOM_MESSAGE_TYPE,
          name: "Custom Message",
        },
      ],
      CUSTOM_MESSAGE_TYPE,
      previewModalName: "mailPreviewModal",
      isPreviewLoading: false,
      previews: [],
    };
  },
  computed: {
    ...mapState({
      mailingTemplates: (state) => state.mailingTemplates.list,
    }),
    customTemplates() {
      if (this.mailingTemplates && !this.mailingTemplates.length) return;
      return this.mailingTemplates
        .filter(
          (item) => item.event === BookingMailTypesEnum.CUSTOM && !item.isPaused
        )
        .map((item) => {
          return {
            value: item.id,
            name: item.subject,
          };
        });
    },
    bookingId() {
      return this.$route.params.id;
    },
  },
  watch: {
    email(val) {
      this.form.email = val || "";
    },
    async ["form.type"]() {
      if (this.form.type === BookingMailTypesEnum.CUSTOM) {
        try {
          this.isLoading = true;
          await this.fetchMailingTemplates();
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    this.form.email = this.email || "";
  },
  methods: {
    ...mapActions({
      fetchMailingTemplates: "mailingTemplates/fetchMailingTemplates",
    }),
    close() {
      this.form = {
        ...this.form,
        templateId: "",
        type: "",
        subject: "",
        message: "",
      };
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("send", this.form);
    },
    async handleShowPreview() {
      try {
        const { type, subject, message, templateId } = this.form;
        if (this.bookingId) {
          this.isPreviewLoading = true;
          this.previews = await MailingTemplatesService.getPreview({
            bookingId: this.bookingId,
            message,
            type,
            subject,
            templateId,
          });
          this.$modal.show(this.previewModalName);
        }
      } catch (err) {
        console.log("err > ", err);
      } finally {
        this.isPreviewLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.bookingMailModal {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }

  &__titleWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close {
    position: absolute;
    left: 16px;
    top: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;

    &.standard {
      margin-bottom: 24px;
    }
  }

  &__tip {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $secondary-200;
    color: $secondary-500;
    font-size: 12px;
    line-height: 20px;
    padding: 12px 16px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    color: #286eed;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
    transition: all 0.3s;

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}
</style>
