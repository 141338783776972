<template>
  <custom-modal :name="name" @before-close="cancelConfirmDialog.cancel()">
    <div class="cancel-booking-confirm">
      <div class="cancel-booking-confirm__title">Cancel booking?</div>
      <div class="cancel-booking-confirm__message">
        You want to cancel booking on<br />
        {{ cancelConfirmDialog.state.datetime }}
      </div>
      <div class="cancel-booking-confirm__actions">
        <Button is-block isSmall @click="cancelConfirmDialog.ok('refund')">
          Cancel with refund
        </Button>
        <Button
          is-block
          isSmall
          isOutlined
          @click="cancelConfirmDialog.ok('no-refund')"
        >
          Cancel without refund
        </Button>
        <Button
          is-block
          isOutlined
          variant="secondary"
          isSmall
          @click="cancelConfirmDialog.cancel()"
        >
          Back
        </Button>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import Vue from "vue";

const state = Vue.observable({
  active: false,
  datetime: null,
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));

const reset = () => {
  state.active = false;
  state.datetime = null;
};

export const cancelConfirmDialog = {
  get state() {
    return state;
  },

  open({ datetime }) {
    state.datetime = datetime;
    state.active = true;
    return dialogPromise();
  },

  ok(status) {
    close(status);
    reset();
  },

  cancel() {
    close("back");
    reset();
  },
};

export default {
  name: "CancelBookingConfirm",
  data() {
    return {
      cancelConfirmDialog,
      name: "cancelConfirmModal",
    };
  },
  computed: {
    isActive() {
      return this.cancelConfirmDialog.state.active;
    },
  },
  watch: {
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-booking-confirm {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $secondary-500;
    text-align: center;
  }

  &__message {
    font-size: 16px;
    line-height: 28px;
    color: $secondary-500;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
