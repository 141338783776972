var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bookingLogCard"},[_c('div',{staticClass:"bookingLogCard__titleRow"},[_c('span',{class:[
        'bookingLogCard__title',
        {
          'm-cancel': _vm.isCancel,
          'm-paid': _vm.isPaid,
          'm-custom': _vm.isCustom,
          'm-warning': _vm.isWarning,
        },
      ]},[_vm._v(" "+_vm._s(_vm.title)+" "+_vm._s(_vm.formattedAmount)+" ")]),_c('span',[_vm._v(_vm._s(_vm.formattedDate))])]),_c('div',{staticClass:"m-flex"},[_c('div',[_c('p',{staticClass:"bookingLogCard__text"},[_vm._v(" "+_vm._s(_vm.log.text || _vm.$t("This technical message was created automatically"))+" ")]),(_vm.metadataHtml)?_c('div',{staticClass:"bookingLogCard__metadata",domProps:{"innerHTML":_vm._s(_vm.metadataHtml)}}):_vm._e()]),_c('p',{staticClass:"bookingLogCard__author"},[_vm._v(" Author: "+_vm._s(_vm.log.userName || "System")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }