<template>
  <div class="bookingLogCard">
    <div class="bookingLogCard__titleRow">
      <span
        :class="[
          'bookingLogCard__title',
          {
            'm-cancel': isCancel,
            'm-paid': isPaid,
            'm-custom': isCustom,
            'm-warning': isWarning,
          },
        ]"
      >
        {{ title }} {{ formattedAmount }}
      </span>
      <span>{{ formattedDate }}</span>
    </div>
    <div class="m-flex">
      <div>
        <p class="bookingLogCard__text">
          {{
            log.text || $t("This technical message was created automatically")
          }}
        </p>
        <div
          v-if="metadataHtml"
          v-html="metadataHtml"
          class="bookingLogCard__metadata"
        ></div>
      </div>
      <p class="bookingLogCard__author">
        Author: {{ log.userName || "System" }}
      </p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {
  formatMoney,
  getStripeDashboardPaymentIntentLink,
  getTimeDifferenceTillDay,
  getVenueTimeFormat,
} from "@/helpers/utils";
import { LogTypeEnum } from "@/helpers/enums";
import { mapState } from "vuex";

export default {
  name: "BookingLogCard",
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      isSuperadmin: (state) => state.users.isSuperadmin,
    }),
    isPaid() {
      return this.log.type === LogTypeEnum.PAID;
    },
    isCancel() {
      return this.log.type === LogTypeEnum.CANCEL;
    },
    isWarning() {
      return this.log.type === LogTypeEnum.WARNING;
    },
    isCustom() {
      return this.log.type === LogTypeEnum.CUSTOM;
    },
    title() {
      switch (this.log.type) {
        case LogTypeEnum.REFUND:
          return `${this.$t("Refunded")}:`;
        case LogTypeEnum.CANCEL:
          return this.$t("Canceled");
        case LogTypeEnum.CUSTOM:
          return this.$t("Attention");
        case LogTypeEnum.UPDATE:
          return this.$t("Updated");
        case LogTypeEnum.PAID:
          return `${this.$t("Paid")}:`;
        case LogTypeEnum.CREATE:
          return this.$t("Created");
        case LogTypeEnum.WARNING:
          return this.$t("Warning");
        case LogTypeEnum.ABANDONED:
          return this.$t("Abandoned");
      }

      return "";
    },
    formattedDate() {
      const timeFormat = getVenueTimeFormat();
      return (
        this.formattedTimeAgo ||
        moment
          .utc(this.log.timestamp.seconds, "X")
          .format(`ddd MMM D, YYYY ${timeFormat}`)
      );
    },
    formattedTimeAgo() {
      return getTimeDifferenceTillDay(
        moment.utc().valueOf(),
        this.log.timestamp.seconds * 1000
      );
    },
    formattedAmount() {
      return this.log.amount
        ? formatMoney(this.log.amount / 100, this.venue.moneyFormat)
        : "";
    },
    metadataHtml() {
      if (this.isSuperadmin) {
        if (
          this.log.type === LogTypeEnum.PAID &&
          this.log.metadata?.paymentIntentId
        ) {
          return `<p>Payment <a href="${getStripeDashboardPaymentIntentLink(
            this.venue.stripeAccount,
            this.log.metadata.paymentIntentId
          )}" target="_blank">${this.log.metadata.paymentIntentId}</a></p>`;
        } else if (
          this.log.type === LogTypeEnum.REFUND &&
          this.log.metadata?.paymentIntents.length
        ) {
          return `<div>${this.log.metadata.paymentIntents
            .map(
              (refundedPayment) =>
                `<p>Payment <a href="${getStripeDashboardPaymentIntentLink(
                  this.venue.stripeAccount,
                  refundedPayment.id
                )}" target="_blank">${
                  refundedPayment.id
                }</a> — Refunded: ${formatMoney(
                  refundedPayment.amount / 100,
                  this.venue.moneyFormat
                )}</p>`
            )
            .join("")}</div>`;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.bookingLogCard {
  background: $white;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  border-radius: 8px;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-400;
  }

  &__title {
    font-size: 14px;
    color: $secondary-500;

    &.m-cancel {
      color: $danger;
    }
    &.m-paid {
      color: $success;
    }
    &.m-warning {
      color: $yellow;
    }
    &.m-custom {
      color: $warning;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    white-space: pre-line;
  }

  &__author {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-400;
  }

  .m-flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__metadata {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-400;

    a {
      color: inherit;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
