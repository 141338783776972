<template>
  <div class="bookingLogs">
    <div class="bookingLogs__titleRow">
      <p class="bookingLogs__title">
        {{ $t("Booking history") }} {{ logs.length ? `(${logs.length})` : "" }}
      </p>
      <Guard permission="bookings.addNote" v-slot="{ isAvailable }">
        <div v-if="isAvailable" class="bookingLogs__icon">
          <Icon
            name="comment"
            is-clickable
            @click="$modal.show('booking-logs-create-modal')"
          />
        </div>
      </Guard>
    </div>
    <span v-if="!logs.length" class="bookingLogs__empty">
      {{ $t("There is no logs yet") }}
    </span>
    <div v-else class="bookingLogs__list">
      <booking-log-card
        v-for="(log, index) in isShow ? logs : [logs[0]]"
        :key="index"
        :log="log"
      />
    </div>
    <span
      v-if="logs.length > 1"
      class="bookingLogs__more"
      @click="isShow = !isShow"
    >
      {{ isShow ? $t("Show less") : $t("Show more") }}
    </span>
    <booking-logs-create-modal @add="handleCreate" />
  </div>
</template>
<script>
import BookingLogCard from "./BookingLogCard";
import { mapActions } from "vuex";
import BookingLogsCreateModal from "./BookingLogsCreateModal";
import alert from "@/plugins/alert";
import Guard from "@/components/common/Guard";

export default {
  name: "BookingLogs",
  components: { Guard, BookingLogsCreateModal, BookingLogCard },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isShow: false,
  }),
  methods: {
    ...mapActions({
      createLog: "bookings/createLog",
    }),
    handleCreate(comment) {
      this.isLoading = true;

      try {
        this.createLog(comment);
        alert.open({
          message: this.$t("Log has been added"),
          variant: "success",
        });
        this.$emit("added");
      } catch (e) {
        alert.open({
          message: this.$t("Failed to add Log"),
          variant: "danger",
        });
      } finally {
        this.$modal.hide("booking-logs-create-modal");
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.bookingLogs {
  width: 100%;

  &__titleRow {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: $secondary-500;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 40px;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    margin-left: auto;
  }

  &__list {
    margin-bottom: 24px;
  }

  &__empty,
  &__more {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-400;
    cursor: pointer;
  }

  &__more {
    cursor: pointer;
  }
}
</style>
