<template>
  <div class="booking-expired-countdown">
    <span class="booking-expired-countdown__text">
      {{ $t("Time left for pay") }}:
    </span>
    <span class="booking-expired-countdown__timer">
      {{ expiredCountdown.minutes }}:{{ getSeconds(expiredCountdown.seconds) }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BookingExpiredCountdown",
  props: {
    expiresAt: {
      type: [Object],
      default: null,
    },
    isPaymentExpired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expiredCountdownSeconds: null,
      expiredCountdownInterval: null,
    };
  },
  computed: {
    expiredCountdown() {
      return {
        minutes: Math.floor(this.expiredCountdownSeconds / 60),
        seconds: this.expiredCountdownSeconds % 60,
      };
    },
  },
  created() {
    const expiresAtSeconds = this.expiresAt?.seconds;
    if (expiresAtSeconds && expiresAtSeconds * 1000 > moment.utc().valueOf()) {
      this.expiredCountdownSeconds = Number(
        ((expiresAtSeconds * 1000 - moment.utc().valueOf()) / 1000).toFixed(0)
      );

      if (this.expiredCountdownSeconds <= 0) {
        this.$emit("update:isPaymentExpired", true);
      }

      const expiredCountdownIntervalHandler = async () => {
        this.expiredCountdownSeconds -= 1;

        if (this.expiredCountdownSeconds === 0) {
          this.$emit("expired");
          this.$emit("update:isPaymentExpired", true);
          clearInterval(this.expiredCountdownInterval);
        }
      };

      this.expiredCountdownInterval = setInterval(
        expiredCountdownIntervalHandler,
        1000
      );
    }
  },
  beforeDestroy() {
    clearInterval(this.expiredCountdownInterval);
  },
  methods: {
    getSeconds(seconds) {
      return seconds < 10 ? `0${seconds}` : seconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-expired-countdown {
  background: $secondary-200;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $secondary-500;
  }

  &__timer {
    color: $primary;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }
}
</style>
